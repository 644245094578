import { MessageDeliveredIcon, MessageSentIcon } from 'assets/icon/icons';
import Avatar from 'components/Avatar/Avatar';
import EmptyState from 'components/EmptyState/EmptyState';
import Loader from 'components/Loader/Loader';
import ModalComponent from 'components/Modal/Modal';
import { ChatRoom, ChatWithRelations } from 'generated';
import { Dispatch, FC, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { useChatSocketContext } from 'utils/chatSocketProvider/ChatSocketProvider';
import { formatDate } from 'utils/helpers';
import { purifyHtml } from 'utils/html-purify';
import CheckFileType from './checkFileType/CheckFileType';

type MessageType = ChatWithRelations & { frontendId?: string };
interface MessageProps {
  message: MessageType;
  activeChatRoom?: ChatRoom;
  handleImageBlowup: (image: any) => void;
  readChatMessage: (data: { userId: string; chatId: string }) => void;
}

export const ImageBlowup = ({ selectedImage, isOpen, closeBlowUp }) => {
  return (
    <ModalComponent title="Preview Image" size="lg" isOpen={isOpen} onClose={closeBlowUp}>
      <div className="w-10/12 h-4/6 flex  mx-auto justify-center items-center">
        <img src={selectedImage} alt={selectedImage} />
      </div>
    </ModalComponent>
  );
};

const Message = ({ message, handleImageBlowup, readChatMessage }: MessageProps) => {
  const user = useAppSelector((state) => state.user.currentUser);
  const itemRef = useRef(null);
  const { activeChatRoom } = useChatSocketContext();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // The observed element is now visible
          // Perform any action you need when the item becomes visible
          if (!message.seen?.includes(user?.id)) {
            readChatMessage?.({ userId: user!.id!, chatId: message.id! });
          }
        }
      });
    });

    const itemElement = itemRef.current;
    observer.observe(itemElement);

    // Clean up the observer on component unmount
    return () => {
      observer.unobserve(itemElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]); // Re-run the effect when the text prop changes

  const renderIcon = (message?: MessageType) => {
    if (message.senderId === user?.id && activeChatRoom?.type === 'direct') {
      if (message.seen?.includes(activeChatRoom?.receiverMemberId)) {
        return <MessageDeliveredIcon color="blue" />;
      }
      if (!message?.frontendId) {
        return <MessageDeliveredIcon />;
      }
      return <MessageSentIcon />;
    }
  };

  return (
    <section
      ref={itemRef}
      className={`flex w-full ${
        message?.senderId === user?.id || message?.senderCoachId?.length > 0
          ? 'justify-end'
          : 'justify-start'
      }`}
    >
      <div className="flex flex-col items-end space-x-3 h-max">
        <div className="flex space-x-3 items-end">
          {message?.senderTypeEnum === 'user' && (
            <Avatar src={message?.senderImage} className="!h-10 !w-10" />
          )}
          <section>
            <div
              className={`${
                message?.senderTypeEnum === 'user'
                  ? 'bg-white text-black shadow-lg'
                  : 'bg-[#E8EAFD] text-[#0D1C8C]'
              } w-max max-w-md rounded-lg`}
            >
              {message?.senderId !== user?.id && (
                <div className="text-xs px-4 py-1 font-medium">~{message?.senderName}</div>
              )}
              <CheckFileType
                file={message?.resourceUrl}
                handleImageBlowup={handleImageBlowup}
                height={message?.message !== '' ? '250px' : ''}
                width={message?.message !== '' ? '280px' : ''}
                cursor="zoom-in"
              />
              <div
                className="p-3 break-words"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{ __html: purifyHtml(message?.message) }}
              />
            </div>
          </section>
        </div>
        <div className="flex space-x-2">
          <span className="text-[9px] flex justify-end mt-1 text-iconGray">
            {formatDate(message?.created_at)}
          </span>

          <span>{renderIcon(message)}</span>
        </div>
      </div>
    </section>
  );
};

interface MessagesProps {
  messages: (ChatWithRelations & { frontendId?: string })[];
  loading: boolean;
  page: number;
  totalElements: number;
  isInitialLoading: boolean;
  totalPages: number;
  activeChatRoom?: ChatRoom;
  setPage: Dispatch<SetStateAction<number>>;
  readChatMessage: (data: { userId: string; chatId: string }) => void;
}

const Messages: FC<MessagesProps> = ({
  messages,
  page,
  isInitialLoading,
  totalPages,
  setPage,
  readChatMessage,
}) => {
  const [selectedImage, setSelectedImage] = useState('');
  const [openBlowUp, setOpenBlowUp] = useState(false);
  const handleImageBlowup = (image: string) => {
    setSelectedImage(image);
    setOpenBlowUp(true);
  };
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const chatEndRef: RefObject<HTMLDivElement> = useRef(null);
  const [hasMore, setHasMore] = useState<boolean>(page < totalPages);

  useEffect(() => {
    if (!isInitialLoading) {
      scrollToBottom();
    }
  }, [isInitialLoading]);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  };

  const closeBlowUp = () => {
    setOpenBlowUp(false);
  };

  useEffect(() => {
    setHasMore(page < totalPages);
  }, [page, totalPages]);

  const fetchMoreMessages = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  console.log({ messages });

  return (
    <div
      id="scrollableDiv"
      ref={scrollContainerRef}
      className="h-full overflow-auto flex flex-col-reverse scrollbar-hide"
    >
      {isInitialLoading ? (
        <Loader />
      ) : messages?.length === 0 ? (
        <>
          <EmptyState title="No messages sent yet" description="Send the first message" />
        </>
      ) : (
        <>
          <InfiniteScroll
            className="px-5 gap-4 flex flex-col-reverse p-12"
            dataLength={messages?.length}
            next={fetchMoreMessages}
            inverse={true}
            hasMore={hasMore}
            loader={<Loader />}
            height={scrollContainerRef?.current?.offsetHeight}
            scrollableTarget="scrollableDiv"
            endMessage={
              <div className="w-full flex justify-center">
                <span className="text-center text-xs font-normal text-gray-500 bg-gray-100 rounded-full w-max p-2 px-4">
                  No more messages!
                </span>
              </div>
            }
          >
            {messages?.map((item, index) => (
              <Message
                message={item}
                key={index}
                handleImageBlowup={handleImageBlowup}
                readChatMessage={readChatMessage}
              />
            ))}
            <div ref={chatEndRef} />
          </InfiniteScroll>
          <ImageBlowup
            selectedImage={selectedImage}
            closeBlowUp={closeBlowUp}
            isOpen={openBlowUp}
          />
        </>
      )}
    </div>
  );
};

export default Messages;
